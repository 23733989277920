@import 'Constants';


.fc-button-primary {
  background-color: $primary!important;
  border: none!important;
  font-weight: bold!important;
  border-radius: 40px!important;
  border: 0!important;
  text-align: center!important;
  padding: 0 1.6rem!important;
  margin-bottom: .7em!important;
  height: 3rem!important;
  box-shadow: 0 4px 5px $box-shadow-color;
}

.fc-prev-button {
  margin-right: 5px!important;
}

.asapen-day-cell > div > a{
  color: black!important;
}

.asapen-order-event {
  background-color: $grey;
  margin-bottom: 5px;
  padding: 5px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.fc-daygrid-day-number {
  color: #000!important;
}

.fc-daygrid-event {
  color: #000!important;
}
