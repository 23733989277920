@import './src/Styles/Constants';

.badgeContainer {
  position: relative;
}

.badge {
  background-color: $red;
  color: white;
  position: absolute;
  top: 0;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8em;
  font-weight: bold;
}
