@import 'src/Styles/Constants';

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  top: -7px;
  right: -7px;

  &.success > span {
    background-color: $primary;
  }

  &.warning > span {
    background-color: #E8DF00;
  }

  &.danger > span {
    background-color: #CE3939;
  }

  > span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;

    background-color: #989898;

    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font-weight: 700;
    font-size: .8em ;
    text-align: center;

    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
}

.ribbon::before {
  top: 0;
  left: 0;
}

.ribbon::after {
  bottom: 0;
  right: 0;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
  border-top-color: transparent;
  border-right-color: transparent;
}

