@import 'src/Styles/Constants';

.menuLink {
  width: 90%;
  height: 2.7rem;
  padding: 0 .8rem;
  border-radius: 40px;

  display: flex;

  color: #9F9F9F;
  font-size: 1.2em;
  line-height: 2.7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  > .menuLinkIcon > svg{
    fill: #9F9F9F;
  }

  &:hover {
    transform: scale(1.08);
  }

  &.active {
    background-color: $primary;
    box-shadow: 0 10px 10px rgba(0,0,0, .1);
    font-weight: 800;
    color: white;

    > .menuLinkIcon > * {
      fill: white;
    }
  }
}


.menuLinkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  width: 45px;

  > svg {
    max-height: 55%;
    fill: #9F9F9F;
  }

  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}


.submenu {
  max-height: 0;

  transition: all .4s ease-in-out;
  overflow: hidden;

  &.open {
    max-height: 500px;
  }
}
