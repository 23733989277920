@import 'src/Styles/Constants';

.layout {
  display: flex;
  height: 100%;
  width: 100%;
}

.menu {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: min-content auto min-content;
  background-color: white;
  padding: 20px 0;
  width: clamp(195px, 20vw, 275px);
  max-width: 250px;

  > * {
    overflow: hidden;
  }

  transition: all .4s ease-in-out;

  &.closed {
    max-width: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  top: 30px;
  right: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;

  > svg {
    fill: $primary;
  }

}

