.descriptionContainer {
  font-size: .9em;
}

.separator {
  border-width: 0;
  border-top-width: 1px;
  margin: 2px 0px;
  padding: 2px 0px;
}
