@import 'Styles/Utils';
@import 'Styles/Constants';

@import 'Styles/button';
@import 'Styles/input';

@import 'Styles/ant_overrides';
@import 'Styles/fullcalendar_overrides';


hr {
  padding: 5px 0 5px 0;
  border: 0;
  width: 100%;
  border-top: 1px solid #DEDEDE;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99a0ac;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
