.container {
  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1)
  }

  padding: 15px;
  border-radius: 3px;
  cursor: pointer;
  height: 100%;
}
