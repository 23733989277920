.uploadedFilesContainer {
  display: flex;
  flex-wrap: wrap;
}

.uploadedFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.uploadedFileContainer {
  width: 150px;
  margin: 0 10px 10px 0;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

.uploadedFileDescription {
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
