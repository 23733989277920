@import 'Constants';

.button {
  border-radius: 40px;
  border: 0;
  text-align: center;
  padding: 0 1.6rem;
  margin-bottom: .7em;
  height: 3rem;
  line-height: 3rem;
  cursor: pointer;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  box-shadow: 0 4px 5px $box-shadow-color;

  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  // Style for the text inside the button
  > div {
    font-weight: 600;
  }

  &.primary {
    color: white;
    background-color: $primary;
  }


  &.default {
    background: $grey;
    color: white;
  }

  &.danger {
    color: white;
    background-color: $red;
  }

  &.primary {
    color: white;
    background-color: $primary;
  }

  &.default {
    background: $grey;
    color: white;
  }

  &.danger {
    color: white;
    background-color: $red;
  }

  &.warning {
    background-color: $yellow;
  }

  &:hover:not(:disabled) {
    transform: scale(1.04);

    &.primary {
      background-color: darken($primary, 10);
    }

    &.default {
      background-color: darken($grey, 10);
    }

    &.danger {
      background-color: darken($red, 10);
    }
  }

  &.block {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.85;
  }

}
