.customerContainer {
  border-radius: 6px;
  background-color: #F5F5F2;
  padding: 10px;
}

.title {
  font-weight: bold;
}

.projectTitle {
  font-weight: bold;
  padding-top: 20px;
}

.row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
