@import 'src/Styles/Constants';


.container {
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

.innerContainer {
  max-width: 1000px;
  padding: 50px 0;
  height: fit-content;
}

.title  {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2em;
  color: rgba(147, 147, 147, .5);
  margin: 0;
  padding-bottom: 10px;
}

.upload > div {
  width: 100%;
}
