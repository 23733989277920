@import 'src/Styles/Constants';

.column {
  padding: 30px;
  border-width: 0;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  border-color: #DBDBDB;

  .columnHeadline {
    color: #9F9F9F;
    white-space: nowrap;
  }

}

.columnText {
  font-weight: bold;
  font-size: 20px;

  &.green {
    color: $primary;
  }

  &.grey {
    color: #9F9F9F;
  }

  &.darkGrey {
    color: #2b2b2b;
  }

  &.blue {
    color: #4B69A5;
  }
}
