.questionRow:nth-child(odd) {
  background-color: #EEE;
}

.answer {
  padding: 5px 10px
}

.answerHeadline {
  margin-bottom: 4px;
  background-color: lightgrey;
}

.answerRadio {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.answerRadio > div {
  display: flex;
}

.answerRadioOption {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answerRadioBox {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.answerText {
  align-items: center;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.answerTextValue {
  display: flex;
  justify-content: flex-end;
  min-width: 25%;
  padding-bottom: 3px;
  border-bottom: 1px solid black;
  margin-right: 5px;
}

.answerTextarea {
  align-items: flex-start;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
