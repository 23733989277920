.container {

  padding: 30px;
  background-color: rgb(83,86,89);

  display: grid;
  grid-gap: 30px;
  justify-content: center;


  overflow-y: auto;
}
