@import "Constants";

label {
  color: #8B8B8B;
}

.input {
  outline: 0;
  padding: 0.85rem;
  border-radius: 6px;
  line-height: 3rem;
  height: 3rem;
  display: inline-block;
  border: none;
  font-size: 1rem;
  overflow: hidden;
  cursor: pointer;

  &:not(.input-invisible) {
    border: 1px solid #E0E0E0;
    color: #767676;
    background-color: #F5F5F2;
  }

  &.full-width {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid $red;
  }

  > div > input {
    color: #9F9F9F !important
  }

  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 3em!important;
  }
}

.input::placeholder, .textarea::placeholder {
  color: #C3C3C2;
}

textarea.input {
  height: auto;
  border-radius: 6px;
  line-height: 1.3rem;
}

div.input {
  display: flex;
  justify-content: space-between;

  padding-top: 0;
  background-color: #F5F5F2;

  > input {
    flex: 1;
    border: none;
    font-size: 0.9rem;
    height: 3rem;
    outline: none;
    background-color: transparent;
    max-width: 70%;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select.input {
  padding-top: 0!important;
}

select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  margin: 0;
  border: none;
  outline: none;
  color: #9F9F9F;
  //background: url('/svg/arrow-down.svg') no-repeat right ;
  background-position-x: 97%;
}


//

.paper-field {
  background-color: #f7f7f6!important;
  padding: .4rem 0.3rem;
  border-radius: 6px;
  border: none;
}

.paper-input {
  outline: 0;
  padding: .4rem 0.3rem;
  border-radius: 6px;
  border: none;
  margin-bottom: 4px;
  color: black;
  background-color: #f7f7f6!important;

  &:disabled {
    cursor: not-allowed;
  }

  &.large {
    font-size: 1.5em;
  }

  &.small {
    padding: .2rem 0.3rem;
    font-size: 13px;
  }

  &.full-width {
    width: 100%;
  }

  &.error {
    border: 1px solid $red;
  }

  > input {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
  }
}

div.paper-input {
  display: flex;
  align-items: center;
}
