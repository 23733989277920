$grey: #989898;

$red: #CE3939;

$green: #A6CE39;
$primary: #F39324;

$box-shadow-color: rgba(0,0,0,0.16);

$yellow: #E8DF00;
