@import 'Styles/Constants';


.typeHeadline {
  margin-top: 40px!important;
  color: black!important;
  font-weight: bold;
  font-size: 1.6em;
  width: 100%;
}

.detailsContainer {
  display: flex;
  align-items: flex-end;
  max-width: 40%;
  min-width: 30%;
}

.defaultTextContainer {
  font-size: .9em;
}

.descriptionContainer {
  font-size: .9em;
}

.seperator {
  border-width: 0;
  border-top-width: 1px;
  margin: 2px 0px;
  padding: 2px 0px;
}

.inputError {
  padding: 5px;
  border: 1px solid #CE3939;
  border-radius: 6px;
}

.uploadedFilesContainer {
  display: flex;
  flex-wrap: wrap;
}

.uploadedFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.uploadedFileContainer {
  width: 150px;
  height: 150px;
  margin: 0 10px 10px 0;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
  cursor: pointer;
  border: 1px solid transparent;

  &.selected {
    border: 1px solid $primary;
  }
}

.uploadedFileDescription {
  margin: 5px;
  width: 100%;
}
