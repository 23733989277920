.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
}
.title  {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3em;
  color: rgba(147, 147, 147, .5);
  margin: 0;
  padding: 0 0 0 15px;
}
.icon > svg {
  height: 3em;
  width: 3em;
  fill: rgba(147, 147, 147, .5);
}
