@import 'src/Styles/Constants';

.pill {
  border-radius: 10px;
  width: 7px;
  height: 100%;
  min-height: 40px;
  margin-right: 5px;

  &.default {
    background-color: #989898;
  }

  &.success {
    background-color: $primary;
  }

  &.warning {
    background-color: #E8DF00;
  }

  &.danger {
    background-color: #CE3939;
  }
}
