@import 'Constants';

// Table overrides
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #DEDEDE!important;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid #DEDEDE!important;
  background-color: white!important;
  color: #D0D0D0!important;
}

.ant-table-cell {
  color: #767676;
}

.ant-table-row > .ant-table-cell:nth-child(2n) {
  background-color: #F8F8F8!important;
}

.ant-pagination-item, .ant-pagination-item-link {
  border-radius: 6px!important;

  > a {
    color: #767676!important;
  }

  &.ant-pagination-item-active {
    border-color: $primary!important;
  }
}

// Notifications
.notification {
  border-radius: 10px!important;

  &-success{
    background-color: $primary!important;

    > div > div > div {
      color: white!important;
    }

    > a {
      color: white!important;
    }
  }

  &-warning{
    background-color: #E8DF00!important;

    > div > div > div {
      color: white!important;
    }

    > a {
      color: white!important;
    }
  }

  &-error{
    background-color: $red!important;

    > div > div > div {
      color: white!important;
    }

    > a {
      color: white!important;
    }
  }
}

.ant-notification-notice-message {
  font-weight: bold;
  text-transform: uppercase;
}

// Collapse

.ant-collapse {
  border-radius: 6px!important;
}

.ant-collapse-header {
  background-color: #F5F5F2!important;
  border-radius: 6px!important;
  color: #8B8B8B!important;
}


// Inputs
.ant-picker {
  border-radius: 6px!important;
  border: 1px solid #E0E0E0!important;
}

.ant-picker-focused {
  outline: none!important;
  box-shadow: none!important;
}


// Modal

.ant-modal-content {
  border-radius: 6px!important;
}

// Tabs

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary!important;
}

.ant-tabs-tab:hover {
  color: $primary!important;
}

.ant-tabs-ink-bar {
  background: $primary!important;
}
