@import 'src/Styles/Constants';

.notice {
  width: 100%;
  color: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 6px;

  &.default {
    background-color: #989898;
  }

  &.success {
    background-color: $primary;
  }

  &.warning {
    background-color: #E8DF00;
  }

  &.danger {
    background-color: #CE3939;
  }
}
