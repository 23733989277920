.container {
  position: relative;
  height: 100%;
}

.overlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.8);
  backdrop-filter: blur(5px);
  z-index: 1000
}

.overlayContent {
  padding: 30px 80px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
  border: 1px solid #DBDBDB;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  padding: 15px 0;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
